<template>
  <div class="CompanyAdminSettingsForm">
    <ui-form validate>
      <ui-validate @status="({ detail }) => formStatus = detail">
        <div
          class="CompanyAdminSettingsForm__wrapper mb-4 w-50"
          data-test-id="personal_information-form"
        >
          <ui-text-input
            v-validate.input="{
              isRequired: {
                message: $t('refactor.company_signup.errors.mandatory'),
                whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
              },
            }"
            :label="$t('business_profile.company.display_name_label')"
            :placeholder="$t('business_profile.company.display_name_placeholder')"
            :disabled="!canEditSettings"
            :value="formData.commercialName"
            data-masked
            data-test-id="comercial_name-input"
            class="d-block mt-5"
            name="commercialName"
            @changevalue="setCommercialName"
          />
          <ui-text-input
            :label="$t('business_profile.company.legal_name_label')"
            :placeholder="$t('business_profile.company.legal_name_placeholder')"
            :value="companyData.fiscal_name"
            data-masked
            disabled
            data-test-id="fiscal_name-input"
            class="d-block mt-4"
            name="fiscalName"
          />
          <InputTax
            :label="$t('business_profile.company.tax_label')"
            :placeholder="$t('business_profile.company.tax_label')"
            :model-value="companyData.fiscal_number"
            :country="companyData.countryCode || COUNTRIES_ISO_CODES.spain"
            data-masked
            is-disabled
            data-test-id="fiscal_number-input"
            class="d-block mt-4"
            name="fiscalNumber"
          />
          <ui-text-input
            v-for="specificField in specificFields"
            :key="specificField.name"
            v-validate="specificField.validation"
            v-bind="specificField.props"
            :data-test-id="specificField.dataTestId"
            class="d-block mt-4"
          />
          <ui-location-input
            v-validate.input="{
              isRequired: {
                message: $t('refactor.company_signup.errors.mandatory'),
                whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
              },
            }"
            :label="$t('business_profile.company.company_address_label')"
            :value="formData.address"
            :gkey="googleMapsKey"
            :allowed-countries.prop="[currentCountry]"
            consent
            class="w-100 mt-4"
            @changeplace="({ detail }) => setAddress(detail)"
          />
          <ui-text-input
            :label="$t('business_profile.company.company_address_details_label')"
            :placeholder="$t('business_profile.company.company_address_details_placeholder')"
            :disabled="!canEditSettings"
            :value="formData.addressDetails"
            data-masked
            data-test-id="address_detail-input"
            class="d-block mt-4"
            name="addressDetail"
            @changevalue="setAddressDetails"
          />
          <PhoneNumberWrapper
            :country="companyData.countryCode"
            :disabled="!canEditSettings"
            :label="$t('refactor.user_signup.step_4.fields.phone_number')"
            :placeholder="$t('refactor.user_signup.step_4.fields.phone_number')"
            :value="formData.phone"
            data-test-id="phone-input"
            class="d-block mt-4"
            data-masked
            name="phone"
            @change="setPhoneNumber"
            @isValid="isValid => isPhoneNumberValid = isValid"
          />
          <ui-select
            :label="$t('business_profile.company.size')"
            :options.prop="employeesOptions"
            :placeholder="$t('business_profile.company.size')"
            :value="formData.size"
            data-test-id="size-selector"
            name="size"
            class="d-block mt-4"
            data-masked
            @selectoption="setCompanySize"
          />
        </div>
        <ui-button
          v-bind="fetchButtonSpecs()"
          :size="SIZES.large"
          :loading="isLoading"
          :disabled="!isFormValid || !formHasChanged"
          :type="BUTTON_TYPES.submit"
          class="d-block my-5"
          @clickbutton="submit"
        >
          {{ $t('business_profile.action.save') }}
        </ui-button>
      </ui-validate>
    </ui-form>
    <FeedbackModalComponent
      v-model="modal.active.isOpen"
      v-bind="modal.active"
    />
  </div>
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isMatch from 'lodash/isMatch';
import pick from 'lodash/pick';

import cfg from '@/config';
import { Validate } from '@emobg/vue-base';

import { aclService } from '@emobg/access-utils';
import { camelCaseKeys, COUNTRIES_ISO_CODES, snakeCaseKeys } from '@emobg/web-utils';

import * as normalize from '@/utils/normalize';

import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';

import { EDIT_COMPANY_SETTINGS } from '@/constants/permissions';
import { genericErrorArgs, genericSuccessArgs } from '@/constants/defaultModalArgs';
import InputTax from '@/components/InputTax/InputTax';
import PhoneNumberWrapper from '@/components/PhoneNumberWrapper/PhoneNumberWrapper';
import { getCompanyData, putUpdateCompany, setCompanyData } from '@/stores/Company/CompanyMapper';
import { getCurrentCSOperator } from '@/stores/CSOperator/CSOperatorMapper';
import { successThanks } from '@/utils/publicImages';
import { useTheme } from '@/composable/Theme/useTheme';

import { companySizeOptions } from './constants/formValues';

import { companyCountryFields, requiredUserDataFields, specificInputFields } from './constants/specificFieldsByCountry';

export default {

  components: {
    FeedbackModalComponent,
    InputTax,
    PhoneNumberWrapper,
  },

  directives: {
    Validate,
  },

  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },

  data() {
    return {
      formHasChanged: false,
      formData: undefined,
      formStatus: {},
      isLoading: false,
      isPhoneNumberValid: true,
      specificFields: [],
      googleMapsKey: cfg.data.googleMapsKey,
      modal: {
        active: {
          isOpen: false,
          primaryCallToAction: () => {
            this.modal.active.isOpen = false;
          },
        },
      },
    };
  },

  computed: {
    companyData: getCompanyData,
    normalizedPhone() {
      return isEmpty(this.formData.phone)
        ? get(this.$refs, 'phoneCompanyAdmin.phoneNumber', '')
        : normalize.phoneNumber(this.formData.phone);
    },
    currentCountry() {
      const operatorCountry = get(getCurrentCSOperator, 'country_code');
      const companyCountry = get(this.companyData, 'countryCode');

      return operatorCountry !== companyCountry ? companyCountry : operatorCountry;
    },
    isFormValid() {
      return this.formStatus.isValid && this.isPhoneNumberValid;
    },
  },

  created() {
    this.COUNTRIES_ISO_CODES = COUNTRIES_ISO_CODES;
    this.successModal = {
      ...genericSuccessArgs(this.$t),
      title: this.$t('business_profile.company.success_modal_success_title'),
      image: successThanks,
      primaryCallToActionText: this.$t('buttons.got_it'),
      primaryCallToAction: () => { this.modal.active.isOpen = false; },
    };
    this.errorModal = {
      ...genericErrorArgs(this.$t),
      title: this.$t('modal.edit_booking_time.error.title'),
      description: this.$t('modal.edit_booking_time.error.message'),
      primaryCallToActionText: this.$t('modal.cancel_booking.error.try_again'),
      primaryCallToAction: () => { this.modal.active.isOpen = false; },
    };

    const companySpecificFieldsKeys = companyCountryFields[this.currentCountry] || [];
    this.initialData = camelCaseKeys(pick(this.companyData, [...requiredUserDataFields, ...companySpecificFieldsKeys]));
    this.specificFields = pick(
      specificInputFields({ companyData: this.companyData }),
      companySpecificFieldsKeys,
    ) || [];
    this.employeesOptions = companySizeOptions();
    this.formData = { ...this.initialData };
    this.canEditSettings = aclService.hasPermissions(EDIT_COMPANY_SETTINGS);
  },

  methods: {
    putUpdateCompany,
    getCurrentCSOperator,
    setCompanyData,

    syncFormHasChanged() {
      const formData = {
        ...this.formData,
        phone: this.normalizedPhone,
      };
      this.formHasChanged = !isMatch(this.initialData, formData);
    },
    setCommercialName({ detail }) {
      this.formData.commercialName = detail;
      this.syncFormHasChanged();
    },
    setAddress(location) {
      this.formData.address = get(location, 'address');
      this.syncFormHasChanged();
    },
    setAddressDetails({ detail }) {
      this.formData.addressDetails = detail;
      this.syncFormHasChanged();
    },
    setPhoneNumber(phoneNumber) {
      this.formData.phone = phoneNumber;
      this.syncFormHasChanged();
    },
    setCompanySize({ detail }) {
      this.formData.size = detail;
      this.syncFormHasChanged();
    },
    async submit() {
      if (!this.isFormValid) {
        return;
      }

      try {
        this.isLoading = true;
        const formCompanyData = {
          commercial_name: this.formData.commercialName,
          phone_1: this.normalizedPhone,
          size: this.formData.size,
          address: this.formData.address,
          address_details: this.formData.addressDetails,
        };
        await this.putUpdateCompany({
          companyUuid: this.companyData.uuid,
          formCompanyData,
        });
        const snakeCaseFormData = snakeCaseKeys({ ...this.formData });
        const snakeCaseCompanyData = snakeCaseKeys({ ...this.companyData });
        this.setCompanyData({ ...snakeCaseCompanyData, ...snakeCaseFormData });
        this.modal.active = { ...this.modal.active, ...this.successModal, isOpen: true };
        this.initialData = { ...this.formData };
        this.syncFormHasChanged();
      } catch (error) {
        this.modal.active = { ...this.modal.active, ...this.errorModal, isOpen: true };
      }
      this.isLoading = false;
    },
  },
};

</script>
